export const pedals = {
  id: 'pedals',
  key: 'pedals',
  label: 'Pedals',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'pedals',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_pedals'],
  type: 'SelectMultipleFilter',
  translatableLabels: true,
  labelTranslationGroup: 'pedals',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'flat', value: 'flat', label: 'Flat' },
      { key: 'clipless', value: 'clipless', label: 'Clipless' },
      { key: 'hybrid', value: 'hybrid', label: 'Hybrid' },
    ],
  },
};
